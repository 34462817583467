<template>
  <div class="pageContainer flexCloumn">
    <el-row class="searchBar flexCloumn" style="flex-wrap: wrap;width:100%">
      <div class="flexStart flex_1 w_100" style="flex-wrap:wrap;">

<!--        <tchtree v-model="searchForm.class_ids" @change="getlist" :isAll="1"></tchtree>-->
        <el-input v-model="searchForm.stu_name" size="small" placeholder="创建人" style="max-width: 200px; margin-right: 10px;"/>
        <el-input v-model="searchForm.issue_name" size="small" placeholder="问题标题" style="max-width: 200px; margin-right: 10px; margin-left: 10px"/>
        <el-select v-model="searchForm.issue_type" size="small" placeholder="问题类型" style="max-width: 200px; margin-right: 10px; margin-left: 10px">
          <el-option label="工作" value="工作"></el-option>
          <el-option label="生活" value="生活"></el-option>
          <el-option label="教程" value="教程"></el-option>
        </el-select>
        <el-button class="ml10" size="small" icon="el-icon-search" @click="onSearch" type="primary">查询</el-button>
        <el-button size="small" icon="el-icon-refresh" @click="onReset" type="warning">重置</el-button>
      </div>

    </el-row>
    <div class="dividerBar"></div>
    <el-table :data="dataList" height="100%" size="small" stripe border style="width:fit-content;width:100%;">
      <el-table-column type="index" label="序号" width="55" align="center"></el-table-column>
      <el-table-column prop="issue_name" label="标题" width="180"></el-table-column>
      <el-table-column prop="issue_content" label="内容" min-width="120"></el-table-column>
      <el-table-column prop="issue_type" label="类型" width="80"></el-table-column>
      <el-table-column prop="scope" label="是否公开" width="100">
        <template slot-scope="scope">
          <span v-if="scope.row.scope == '1'">不公开</span>
          <span v-if="scope.row.scope == '0'">公开</span>
        </template>
      </el-table-column> <el-table-column prop="location" label="学生位置" width="250"></el-table-column>
      <el-table-column prop="stu_name" label="学生姓名" width="100"></el-table-column>
      <el-table-column prop="phone" label="学生电话" width="100"></el-table-column>
      <el-table-column prop="cdate" label="发布时间" width="150"></el-table-column>
    </el-table>
    <el-pagination @current-change="handlePageChange" @size-change="handleSizeChange" :current-page="page.current_page"
      :page-size="page.per_page" :total="page.count" layout="total, sizes,prev, pager, next"
      :page-sizes="[10, 20, 50, 100]" style="margin-top: 10px;">
    </el-pagination>
    <el-dialog title="活动审核" :visible.sync="dialogVisible" width="30%">
      <el-form ref="form" :model="ruleForm" label-width="80px">

        <el-form-item label="审核状态">
          <el-select v-model="ruleForm.activity_status" placeholder="审核状态" size="small">
            <el-option label="通过" :value="1">通过</el-option>
            <el-option label="不通过" :value="-1">不通过</el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="审核理由">
          <el-input size="small"
                    type="textarea"
                    v-model="ruleForm.remark"
                    placeholder="审核理由"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit" size="small">提交</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import tchtree from "../../com/tchTree.vue";
export default {
  components: { tchtree },
  data() {
    return {

      activeName: "td",
      dataList: [],
      showSP: false,
      spword: "",
      showPrint: false,
      visible: false,
      detailForm: {},
      dialogVisible: false,
      page: {
        count: 0,
        current_page: 1,
        per_page: 50,
        total_page: 0
      },
      searchForm: {},
      ruleForm:{},
    };
  },
  mounted() {
    console.log(sessionStorage.getItem("auth"));
    this.getlist();
  },
  methods: {
    onView(row) {
      this.visible = true
      this.detailForm = row
    },
    getYears() {
      return new Promise((resolve, reject) => {
        this.$http.post("/api/njwx_stu_years").then(res => {
          this.YearList = res.data
          this.searchForm.year = res.data[0].gyear
          resolve()
        }).catch(err => {
          resolve()

        })
      })
    },
    getlist() {
      this.$http
        .post("/api/sys_forum_list", {
          page: this.page.current_page,
          pagesize: this.page.per_page,
          stu_name: this.searchForm.stu_name,
          issue_name: this.searchForm.issue_name,
          issue_type: this.searchForm.issue_type,
          status: this.searchForm.status,
          type:1,
        })
        .then((res) => {
          this.dataList = res.data.data;
          this.page = res.data.page
        });
    },
    handlePageChange(page) {
      this.page.current_page = page
      this.getlist()
    },
    handleSizeChange(val) {
      this.page.current_page = 1;
      this.page.per_page = val;
      this.getlist()
    },
    changeTab() {
      this.getlist();
    },
    onSearch() {
      this.getlist();
    },
    onReset() {
      this.searchForm = {};
      this.getlist();
    },
    review(e) {
      this.ruleForm.id = e.id;
      this.ruleForm.activity_status = undefined
      this.dialogVisible = true;
    },
    onSubmit() {
      this.$http
        .post("api/check_activity", this.ruleForm)
        .then((res) => {
          this.dialogVisible = false;
          this.getlist();
        });
    },
  },
};
</script>
